
import Vue from "vue";
import { HTTP } from "@/utils/http-common";
export default Vue.extend({
  data(): any {
    return {
      showLoadingScreen: true,
      users: {
        data: [],
      },
      companies: {
        data: [],
      },
      newMessage: false,
      publishMessage: "",
      itemId: "",
      multiselectOptions: [],
      editingModel: {
        email: "",
        name: "",
        phone: "",
        role: "client",
        password: "",
        password_confirmation: "",
        companies: [],
      },
    };
  },
  methods: {
    addMessage() {
      this.newMessage = "add";
      for (const key in this.editingModel) {
        if (this.editingModel.hasOwnProperty(key)) {
          if (key !== "companies") {
            this.editingModel[key] = "";
          } else {
            this.editingModel[key] = [];
          }
        }
      }
    },
    startEditing(values: any) {
      this.newMessage = "edit";
      this.itemId = values.id;
      this.editingModel.email = values.email;
      this.editingModel.name = values.name;
      this.editingModel.phone = values.phone;
      this.editingModel.companies = values.companies;
      this.editingModel.role = values.role;
    },
  },
  created() {
    HTTP.get(`api/users`)
      .then((response) => {
        response.data.data.map((user: any) => {
          this.users.data.push({
            id: user.id,
            companies: user.companies.map((company: any) => {
              return company.name;
            }),
            role: user.role,
            email: user.email,
            name: user.name,
            phone: user.phone,
          });
        });

        this.showLoadingScreen = false;
      })
      .catch((e) => {
        console.error(e);
      });
    HTTP.get(`api/companies`)
      .then((response) => {
        response.data.data.forEach((company: any) => {
          this.multiselectOptions.push(company.name);
          this.companies.data.push({
            id: company.id,
            name: company.name,
          });
        });
      })
      .catch((e) => {
        console.error(e);
      });
  },
});
