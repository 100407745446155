var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.showLoadingScreen)?_c('div',{staticClass:"loadingScreen"},[_vm._v(" Ładowanie listy użytkowników… ")]):_vm._e(),(!_vm.showLoadingScreen)?_c('div',{staticClass:"columns",staticStyle:{"margin-top":"35px"}},[_c('button',{staticClass:"btn",on:{"click":_vm.addMessage}},[_vm._v("Dodaj użytkownika")]),(_vm.newMessage)?_c('edition-form',{attrs:{"newMessage":_vm.newMessage,"itemId":_vm.itemId,"editingModel":_vm.editingModel,"requestUrl":'api/user',"companiesList":_vm.companies.data,"formLayout":[
        {
          formField: 'input',
          name: 'Email',
          placeholder: 'Email',
          model: 'email',
        },
        {
          formField: 'input',
          name: 'Imię i nazwisko',
          placeholder: 'Imię i nazwisko',
          model: 'name',
        },
        {
          formField: 'input',
          name: 'Numer telefonu',
          placeholder: 'Numer telefonu',
          model: 'phone',
        },
        {
          formField: 'input',
          name: 'Hasło',
          placeholder: 'Hasło',
          model: 'password',
        },
        {
          formField: 'input',
          name: 'Potwierdzenie hasła',
          placeholder: 'Potwierdzenie hasła',
          model: 'password_confirmation',
        },
        {
          formField: 'select',
          name: 'Rola',
          placeholder: 'Rola',
          model: 'role',
          range: ['administrator', 'distributor', 'client'],
        },
        {
          formField: 'multiselect',
          model: 'companies',
          multiselectOptions: _vm.multiselectOptions,
        },
      ]}}):_vm._e(),_c('table-data',{attrs:{"headers":['ID.', 'Firma', 'Rola', 'Adres e-mail'],"tableData":this.users.data,"adminCategory":'user',"searchItem":'email'},on:{"start-editing":function($event){return _vm.startEditing($event)}}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }